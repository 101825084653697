var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"2rem 6rem"}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"xs":24,"md":16,"lg":16,"offset":"3"}},[_c('h3',{staticStyle:{"font-weight":"bold","color":"#076885"}},[_vm._v("Thông tin tài khoản")]),_c('a-card',{staticStyle:{"margin-bottom":"20px"},attrs:{"title":"Hồ sơ nhân viên"}},[_c('a-form-model',{ref:"ruleForm",staticStyle:{"border":"none","padding":"20px"},attrs:{"model":_vm.form,"layout":"horizontal"}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"xs":24,"md":12,"lg":12}},[_c('a-form-model-item',{attrs:{"prop":"fullName","label":"Tên","rules":[
                  {
                    required: true,
                    message: 'Tên là bắt buộc',
                    trigger:'change'
                  }
                ]}},[_c('a-input',{staticStyle:{"height":"30px"},on:{"blur":function($event){return _vm.DeepTrimValue(_vm.form)}},model:{value:(_vm.form.fullName),callback:function ($$v) {_vm.$set(_vm.form, "fullName", $$v)},expression:"form.fullName"}})],1)],1),_c('a-col',{attrs:{"xs":24,"md":12,"lg":12}},[_c('a-form-model-item',{attrs:{"prop":"email","label":"Địa chỉ email","rules":[
                  {
                    required: true,
                    message: 'Địa chỉ email là bắt buộc',
                    trigger:'change'
                  },
                  {
                    validator: _vm.checkEmail,
                    trigger: 'change'
                  }
                ]}},[_c('a-input',{staticStyle:{"height":"30px"},on:{"blur":function($event){return _vm.DeepTrimValue(_vm.form)}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)],1),_c('a-col',{attrs:{"xs":24,"md":12,"lg":12}},[_c('a-form-model-item',{attrs:{"prop":"phone","label":"Số điện thoại","rules":[
                  {
                    required: true,
                    message: 'Số điện thoại là bắt buộc',
                    trigger: 'change'
                  },
                  {
                    validator: _vm.phoneValidator,
                    trigger: 'change'
                  }
                ]}},[_c('a-input',{staticStyle:{"height":"30px"},on:{"blur":function($event){return _vm.DeepTrimValue(_vm.form)}},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1)],1)],1),(_vm.isEdit === true)?_c('hr'):_vm._e(),(_vm.isEdit === true)?_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"xs":24,"md":24,"lg":24}},[_c('h4',{staticStyle:{"font-weight":"bold","color":"#076885"}},[_vm._v(" Khôi Phục Mật Khẩu ")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loadingResetPass},on:{"click":_vm.resetPassword}},[_vm._v("Gửi email khôi phục mật khẩu cho tài khoản")])],1)],1):_vm._e()],1)],1),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('a-button',{attrs:{"type":"default"},on:{"click":_vm.goToBack}},[_vm._v("Quay lại")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.save}},[_vm._v("Lưu lại")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }